
.pageHeader{
    margin:10px;
    padding:10px;
    font-size:xx-large;
    font-weight:bold;
    color:#1976d2;
}

.pageContent{
    margin:20px;
    padding:20px;
    font-size:large;
    color:black;
    width:100%;
}